import React, {useState, useEffect} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layoutSecure'
import { FaArrowRight, FaFilePdf } from 'react-icons/fa';
import Helmet from 'react-helmet'
import PortableText from '../components/portableText'
import MainReference from '../components/mainReference'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Accordion from '../components/accordion'

import { useSelector, useDispatch } from "react-redux"


export const query = graphql`
    query SitePageQuery {
        site: sanitySiteLanding(_id: { regex: "/(drafts.|)siteLanding/" }) {
            _id
            banner {
                asset {
                    url
                    _id
                }
                ...SanityImage
                alt
            }
            title
            pageImage {
                ...SanityImage
                alt
            }
            subTitle
            _rawPageDescription(resolveReferences: {maxDepth: 5})
            mainReferences {
                ...SanityFile
                ...SanityLink
            }


            categories {
                title
                _rawDecription
                region
                subSections {
                    ...SanityFile
                    ...SanityLink
                    ... on SanitySubSection {
                        _key
                        _type
                        _rawDecription
                        title
                        region
                        sectionLinks {
                            ...SanityFile
                            ...SanityLink
                        }
                        files {
                            ... on SanityMainImage {
                                _key
                                _type
                                alt
                                ...SanityImage
                            }
                            ...SanityVideo
                        }
                    }
                    ...SanityCommonSection
                    
                }
            }
        }

        setting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }
    }
`


const SiteLanding = props => {
    const {data, errors} = props
    // console.log('-----------> data: ', data, props)

    const userInfo = useSelector(state => state.userInfo)

    let userRegion = userInfo ? userInfo.region : ''
    

    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const site = (data || {}).site

    if (!site) {
        throw new Error(
        'Missing "siteLanding". Open the studio at http://localhost:3333 and add some content to "siteLanding" and restart the development server.'
        )
    }

    let  banner = site.banner && site.banner.asset ? site.banner.asset.url : ''
    let setting = (data || {}).setting

    return (
        <Layout>
            <SEO
                title={site.title}
                description={setting.description}
                keywords={setting.keywords}
            />

            {/* banner */}
            <div className='container container-adjust-p'>
            {
                banner ? 
                <div>
                    <img src={banner} alt={site.banner ? site.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
                </div>
                : 
                <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                </div>
            }
            <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight: 100 }}> 
                {/* <div className="container">
                    <div className="row">
                        <h1 className='mb-0' style={{color: 'rgb(245, 235, 220)'}}>{site.title}</h1>
                    </div>
                </div> */}
            </div>
            </div>


            {/* breadcrumb */}
            <section className="p-0">
                <div className="container">
                    <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Site</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </section>
            

            {/* content */}
            <section className="pt-0 pb-2 mb-4">
                <div className="container">
                    <div className="row d-flex justify-content-lg-between">
                        
                        <div className="col-md-6">
                            {site.pageImage && site.pageImage.asset && (
                                <img
                                    src={imageUrlFor(buildImageObj(site.pageImage))
                                        // .width(100)
                                        // .height(100)
                                        .fit('crop')
                                        .url()}
                                    alt={site.pageImage.alt}
                                />
                            )}
                        </div>

                        <div className="col-md-6">
                            <h3>{site.subTitle}</h3>
                            {site._rawPageDescription && <PortableText blocks={site._rawPageDescription} />}

                            {site.mainReferences && <MainReference items={site.mainReferences} userRegion={userRegion} />}
                        </div>
                    </div>
                </div>
            </section>


            {/* accordion */}
            <section className="pt-0 pb-2 mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-12">                            
                            <Accordion items={site.categories} userRegion={userRegion} />
                        </div>
                    </div>
                </div>
            </section>
                

        </Layout>
    )
}

export default SiteLanding